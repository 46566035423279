import React from 'react'

export default function Footer() {
  return (
    <>
     <footer className="footer">
              <div className="d-sm-flex justify-content-center justify-content-sm-between">
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                  Copyright © 2024. All rights reserved.
                </span>
                <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
                  Design & Developed by{" "}
                  <a href="https://www.piramalswasthya.org/" target="_blank">
                    Piramalswasthya
                  </a>
                </span>
              </div>
              <div className="d-sm-flex justify-content-center justify-content-sm-between"></div>
            </footer>
    </>
  )
}
